import _MSI4 from "./MSI.js";
import _checksums2 from "./checksums.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _MSI2 = _MSI4;

var _MSI3 = _interopRequireDefault(_MSI2);

var _checksums = _checksums2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var MSI10 = function (_MSI) {
  _inherits(MSI10, _MSI);

  function MSI10(data, options) {
    _classCallCheck(this, MSI10);

    return _possibleConstructorReturn(this, (MSI10.__proto__ || Object.getPrototypeOf(MSI10)).call(this, data + (0, _checksums.mod10)(data), options));
  }

  return MSI10;
}(_MSI3.default);

exports.default = MSI10;
export default exports;