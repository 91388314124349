import _constants2 from "./constants";
import _Barcode4 from "../Barcode";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _constants = _constants2;
var _Barcode2 = _Barcode4;

var _Barcode3 = _interopRequireDefault(_Barcode2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var ITF = function (_Barcode) {
  _inherits(ITF, _Barcode);

  function ITF() {
    _classCallCheck(this, ITF);

    return _possibleConstructorReturn(this, (ITF.__proto__ || Object.getPrototypeOf(ITF)).apply(this, arguments));
  }

  _createClass(ITF, [{
    key: "valid",
    value: function valid() {
      return this.data.search(/^([0-9]{2})+$/) !== -1;
    }
  }, {
    key: "encode",
    value: function encode() {
      var _this2 = this; // Calculate all the digit pairs


      var encoded = this.data.match(/.{2}/g).map(function (pair) {
        return _this2.encodePair(pair);
      }).join("");
      return {
        data: _constants.START_BIN + encoded + _constants.END_BIN,
        text: this.text
      };
    } // Calculate the data of a number pair

  }, {
    key: "encodePair",
    value: function encodePair(pair) {
      var second = _constants.BINARIES[pair[1]];
      return _constants.BINARIES[pair[0]].split("").map(function (first, idx) {
        return (first === "1" ? "111" : "1") + (second[idx] === "1" ? "000" : "0");
      }).join("");
    }
  }]);

  return ITF;
}(_Barcode3.default);

exports.default = ITF;
export default exports;