import _EAN4 from "./EAN";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _get = function get(object, property, receiver) {
  if (object === null) object = Function.prototype;
  var desc = Object.getOwnPropertyDescriptor(object, property);

  if (desc === undefined) {
    var parent = Object.getPrototypeOf(object);

    if (parent === null) {
      return undefined;
    } else {
      return get(parent, property, receiver);
    }
  } else if ("value" in desc) {
    return desc.value;
  } else {
    var getter = desc.get;

    if (getter === undefined) {
      return undefined;
    }

    return getter.call(receiver);
  }
};

var _EAN2 = _EAN4;

var _EAN3 = _interopRequireDefault(_EAN2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
} // Encoding documentation:
// http://www.barcodeisland.com/ean8.phtml
// Calculate the checksum digit


var checksum = function checksum(number) {
  var res = number.substr(0, 7).split("").map(function (n) {
    return +n;
  }).reduce(function (sum, a, idx) {
    return idx % 2 ? sum + a : sum + a * 3;
  }, 0);
  return (10 - res % 10) % 10;
};

var EAN8 = function (_EAN) {
  _inherits(EAN8, _EAN);

  function EAN8(data, options) {
    _classCallCheck(this, EAN8); // Add checksum if it does not exist


    if (data.search(/^[0-9]{7}$/) !== -1) {
      data += checksum(data);
    }

    return _possibleConstructorReturn(this, (EAN8.__proto__ || Object.getPrototypeOf(EAN8)).call(this, data, options));
  }

  _createClass(EAN8, [{
    key: "valid",
    value: function valid() {
      return this.data.search(/^[0-9]{8}$/) !== -1 && +this.data[7] === checksum(this.data);
    }
  }, {
    key: "leftText",
    value: function leftText() {
      return _get(EAN8.prototype.__proto__ || Object.getPrototypeOf(EAN8.prototype), "leftText", this).call(this, 0, 4);
    }
  }, {
    key: "leftEncode",
    value: function leftEncode() {
      var data = this.data.substr(0, 4);
      return _get(EAN8.prototype.__proto__ || Object.getPrototypeOf(EAN8.prototype), "leftEncode", this).call(this, data, "LLLL");
    }
  }, {
    key: "rightText",
    value: function rightText() {
      return _get(EAN8.prototype.__proto__ || Object.getPrototypeOf(EAN8.prototype), "rightText", this).call(this, 4, 4);
    }
  }, {
    key: "rightEncode",
    value: function rightEncode() {
      var data = this.data.substr(4, 4);
      return _get(EAN8.prototype.__proto__ || Object.getPrototypeOf(EAN8.prototype), "rightEncode", this).call(this, data, "RRRR");
    }
  }]);

  return EAN8;
}(_EAN3.default);

exports.default = EAN8;
export default exports;