import _encoder3 from "./encoder";
import _Barcode4 from "../Barcode.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

exports.checksum = checksum;
var _encoder = _encoder3;

var _encoder2 = _interopRequireDefault(_encoder);

var _Barcode2 = _Barcode4;

var _Barcode3 = _interopRequireDefault(_Barcode2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
} // Encoding documentation:
// https://en.wikipedia.org/wiki/Universal_Product_Code#Encoding


var UPC = function (_Barcode) {
  _inherits(UPC, _Barcode);

  function UPC(data, options) {
    _classCallCheck(this, UPC); // Add checksum if it does not exist


    if (data.search(/^[0-9]{11}$/) !== -1) {
      data += checksum(data);
    }

    var _this = _possibleConstructorReturn(this, (UPC.__proto__ || Object.getPrototypeOf(UPC)).call(this, data, options));

    _this.displayValue = options.displayValue; // Make sure the font is not bigger than the space between the guard bars

    if (options.fontSize > options.width * 10) {
      _this.fontSize = options.width * 10;
    } else {
      _this.fontSize = options.fontSize;
    } // Make the guard bars go down half the way of the text


    _this.guardHeight = options.height + _this.fontSize / 2 + options.textMargin;
    return _this;
  }

  _createClass(UPC, [{
    key: "valid",
    value: function valid() {
      return this.data.search(/^[0-9]{12}$/) !== -1 && this.data[11] == checksum(this.data);
    }
  }, {
    key: "encode",
    value: function encode() {
      if (this.options.flat) {
        return this.flatEncoding();
      } else {
        return this.guardedEncoding();
      }
    }
  }, {
    key: "flatEncoding",
    value: function flatEncoding() {
      var result = "";
      result += "101";
      result += (0, _encoder2.default)(this.data.substr(0, 6), "LLLLLL");
      result += "01010";
      result += (0, _encoder2.default)(this.data.substr(6, 6), "RRRRRR");
      result += "101";
      return {
        data: result,
        text: this.text
      };
    }
  }, {
    key: "guardedEncoding",
    value: function guardedEncoding() {
      var result = []; // Add the first digit

      if (this.displayValue) {
        result.push({
          data: "00000000",
          text: this.text.substr(0, 1),
          options: {
            textAlign: "left",
            fontSize: this.fontSize
          }
        });
      } // Add the guard bars


      result.push({
        data: "101" + (0, _encoder2.default)(this.data[0], "L"),
        options: {
          height: this.guardHeight
        }
      }); // Add the left side

      result.push({
        data: (0, _encoder2.default)(this.data.substr(1, 5), "LLLLL"),
        text: this.text.substr(1, 5),
        options: {
          fontSize: this.fontSize
        }
      }); // Add the middle bits

      result.push({
        data: "01010",
        options: {
          height: this.guardHeight
        }
      }); // Add the right side

      result.push({
        data: (0, _encoder2.default)(this.data.substr(6, 5), "RRRRR"),
        text: this.text.substr(6, 5),
        options: {
          fontSize: this.fontSize
        }
      }); // Add the end bits

      result.push({
        data: (0, _encoder2.default)(this.data[11], "R") + "101",
        options: {
          height: this.guardHeight
        }
      }); // Add the last digit

      if (this.displayValue) {
        result.push({
          data: "00000000",
          text: this.text.substr(11, 1),
          options: {
            textAlign: "right",
            fontSize: this.fontSize
          }
        });
      }

      return result;
    }
  }]);

  return UPC;
}(_Barcode3.default); // Calulate the checksum digit
// https://en.wikipedia.org/wiki/International_Article_Number_(EAN)#Calculation_of_checksum_digit


function checksum(number) {
  var result = 0;
  var i;

  for (i = 1; i < 11; i += 2) {
    result += parseInt(number[i]);
  }

  for (i = 0; i < 11; i += 2) {
    result += parseInt(number[i]) * 3;
  }

  return (10 - result % 10) % 10;
}

exports.default = UPC;
export default exports;