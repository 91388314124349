import _Barcode4 from "../Barcode.js";
import _constants2 from "./constants";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _Barcode2 = _Barcode4;

var _Barcode3 = _interopRequireDefault(_Barcode2);

var _constants = _constants2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
} // This is the master class,
// it does require the start code to be included in the string


var CODE128 = function (_Barcode) {
  _inherits(CODE128, _Barcode);

  function CODE128(data, options) {
    _classCallCheck(this, CODE128); // Get array of ascii codes from data


    var _this = _possibleConstructorReturn(this, (CODE128.__proto__ || Object.getPrototypeOf(CODE128)).call(this, data.substring(1), options));

    _this.bytes = data.split("").map(function (char) {
      return char.charCodeAt(0);
    });
    return _this;
  }

  _createClass(CODE128, [{
    key: "valid",
    value: function valid() {
      // ASCII value ranges 0-127, 200-211
      return /^[\x00-\x7F\xC8-\xD3]+$/.test(this.data);
    } // The public encoding function

  }, {
    key: "encode",
    value: function encode() {
      var bytes = this.bytes; // Remove the start code from the bytes and set its index

      var startIndex = bytes.shift() - 105; // Get start set by index

      var startSet = _constants.SET_BY_CODE[startIndex];

      if (startSet === undefined) {
        throw new RangeError("The encoding does not start with a start character.");
      }

      if (this.shouldEncodeAsEan128() === true) {
        bytes.unshift(_constants.FNC1);
      } // Start encode with the right type


      var encodingResult = CODE128.next(bytes, 1, startSet);
      return {
        text: this.text === this.data ? this.text.replace(/[^\x20-\x7E]/g, "") : this.text,
        data: // Add the start bits
        CODE128.getBar(startIndex) + // Add the encoded bits
        encodingResult.result + // Add the checksum
        CODE128.getBar((encodingResult.checksum + startIndex) % _constants.MODULO) + // Add the end bits
        CODE128.getBar(_constants.STOP)
      };
    } // GS1-128/EAN-128

  }, {
    key: "shouldEncodeAsEan128",
    value: function shouldEncodeAsEan128() {
      var isEAN128 = this.options.ean128 || false;

      if (typeof isEAN128 === "string") {
        isEAN128 = isEAN128.toLowerCase() === "true";
      }

      return isEAN128;
    } // Get a bar symbol by index

  }], [{
    key: "getBar",
    value: function getBar(index) {
      return _constants.BARS[index] ? _constants.BARS[index].toString() : "";
    } // Correct an index by a set and shift it from the bytes array

  }, {
    key: "correctIndex",
    value: function correctIndex(bytes, set) {
      if (set === _constants.SET_A) {
        var charCode = bytes.shift();
        return charCode < 32 ? charCode + 64 : charCode - 32;
      } else if (set === _constants.SET_B) {
        return bytes.shift() - 32;
      } else {
        return (bytes.shift() - 48) * 10 + bytes.shift() - 48;
      }
    }
  }, {
    key: "next",
    value: function next(bytes, pos, set) {
      if (!bytes.length) {
        return {
          result: "",
          checksum: 0
        };
      }

      var nextCode = void 0,
          index = void 0; // Special characters

      if (bytes[0] >= 200) {
        index = bytes.shift() - 105;
        var nextSet = _constants.SWAP[index]; // Swap to other set

        if (nextSet !== undefined) {
          nextCode = CODE128.next(bytes, pos + 1, nextSet);
        } // Continue on current set but encode a special character
        else {
            // Shift
            if ((set === _constants.SET_A || set === _constants.SET_B) && index === _constants.SHIFT) {
              // Convert the next character so that is encoded correctly
              bytes[0] = set === _constants.SET_A ? bytes[0] > 95 ? bytes[0] - 96 : bytes[0] : bytes[0] < 32 ? bytes[0] + 96 : bytes[0];
            }

            nextCode = CODE128.next(bytes, pos + 1, set);
          }
      } // Continue encoding
      else {
          index = CODE128.correctIndex(bytes, set);
          nextCode = CODE128.next(bytes, pos + 1, set);
        } // Get the correct binary encoding and calculate the weight


      var enc = CODE128.getBar(index);
      var weight = index * pos;
      return {
        result: enc + nextCode.result,
        checksum: weight + nextCode.checksum
      };
    }
  }]);

  return CODE128;
}(_Barcode3.default);

exports.default = CODE128;
export default exports;