import _constants2 from "./constants";
import _encoder3 from "./encoder";
import _Barcode4 from "../Barcode";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _constants = _constants2;
var _encoder = _encoder3;

var _encoder2 = _interopRequireDefault(_encoder);

var _Barcode2 = _Barcode4;

var _Barcode3 = _interopRequireDefault(_Barcode2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
} // Base class for EAN8 & EAN13


var EAN = function (_Barcode) {
  _inherits(EAN, _Barcode);

  function EAN(data, options) {
    _classCallCheck(this, EAN); // Make sure the font is not bigger than the space between the guard bars


    var _this = _possibleConstructorReturn(this, (EAN.__proto__ || Object.getPrototypeOf(EAN)).call(this, data, options));

    _this.fontSize = !options.flat && options.fontSize > options.width * 10 ? options.width * 10 : options.fontSize; // Make the guard bars go down half the way of the text

    _this.guardHeight = options.height + _this.fontSize / 2 + options.textMargin;
    return _this;
  }

  _createClass(EAN, [{
    key: "encode",
    value: function encode() {
      return this.options.flat ? this.encodeFlat() : this.encodeGuarded();
    }
  }, {
    key: "leftText",
    value: function leftText(from, to) {
      return this.text.substr(from, to);
    }
  }, {
    key: "leftEncode",
    value: function leftEncode(data, structure) {
      return (0, _encoder2.default)(data, structure);
    }
  }, {
    key: "rightText",
    value: function rightText(from, to) {
      return this.text.substr(from, to);
    }
  }, {
    key: "rightEncode",
    value: function rightEncode(data, structure) {
      return (0, _encoder2.default)(data, structure);
    }
  }, {
    key: "encodeGuarded",
    value: function encodeGuarded() {
      var textOptions = {
        fontSize: this.fontSize
      };
      var guardOptions = {
        height: this.guardHeight
      };
      return [{
        data: _constants.SIDE_BIN,
        options: guardOptions
      }, {
        data: this.leftEncode(),
        text: this.leftText(),
        options: textOptions
      }, {
        data: _constants.MIDDLE_BIN,
        options: guardOptions
      }, {
        data: this.rightEncode(),
        text: this.rightText(),
        options: textOptions
      }, {
        data: _constants.SIDE_BIN,
        options: guardOptions
      }];
    }
  }, {
    key: "encodeFlat",
    value: function encodeFlat() {
      var data = [_constants.SIDE_BIN, this.leftEncode(), _constants.MIDDLE_BIN, this.rightEncode(), _constants.SIDE_BIN];
      return {
        data: data.join(""),
        text: this.text
      };
    }
  }]);

  return EAN;
}(_Barcode3.default);

exports.default = EAN;
export default exports;