import _constants2 from "./constants";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _constants = _constants2; // Match Set functions

var matchSetALength = function matchSetALength(string) {
  return string.match(new RegExp("^" + _constants.A_CHARS + "*"))[0].length;
};

var matchSetBLength = function matchSetBLength(string) {
  return string.match(new RegExp("^" + _constants.B_CHARS + "*"))[0].length;
};

var matchSetC = function matchSetC(string) {
  return string.match(new RegExp("^" + _constants.C_CHARS + "*"))[0];
}; // CODE128A or CODE128B


function autoSelectFromAB(string, isA) {
  var ranges = isA ? _constants.A_CHARS : _constants.B_CHARS;
  var untilC = string.match(new RegExp("^(" + ranges + "+?)(([0-9]{2}){2,})([^0-9]|$)"));

  if (untilC) {
    return untilC[1] + String.fromCharCode(204) + autoSelectFromC(string.substring(untilC[1].length));
  }

  var chars = string.match(new RegExp("^" + ranges + "+"))[0];

  if (chars.length === string.length) {
    return string;
  }

  return chars + String.fromCharCode(isA ? 205 : 206) + autoSelectFromAB(string.substring(chars.length), !isA);
} // CODE128C


function autoSelectFromC(string) {
  var cMatch = matchSetC(string);
  var length = cMatch.length;

  if (length === string.length) {
    return string;
  }

  string = string.substring(length); // Select A/B depending on the longest match

  var isA = matchSetALength(string) >= matchSetBLength(string);
  return cMatch + String.fromCharCode(isA ? 206 : 205) + autoSelectFromAB(string, isA);
} // Detect Code Set (A, B or C) and format the string


exports.default = function (string) {
  var newString = void 0;
  var cLength = matchSetC(string).length; // Select 128C if the string start with enough digits

  if (cLength >= 2) {
    newString = _constants.C_START_CHAR + autoSelectFromC(string);
  } else {
    // Select A/B depending on the longest match
    var isA = matchSetALength(string) > matchSetBLength(string);
    newString = (isA ? _constants.A_START_CHAR : _constants.B_START_CHAR) + autoSelectFromAB(string, isA);
  }

  return newString.replace(/[\xCD\xCE]([^])[\xCD\xCE]/, // Any sequence between 205 and 206 characters
  function (match, char) {
    return String.fromCharCode(203) + char;
  });
};

export default exports;