var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var InvalidInputException = function (_Error) {
  _inherits(InvalidInputException, _Error);

  function InvalidInputException(symbology, input) {
    _classCallCheck(this, InvalidInputException);

    var _this = _possibleConstructorReturn(this, (InvalidInputException.__proto__ || Object.getPrototypeOf(InvalidInputException)).call(this));

    _this.name = "InvalidInputException";
    _this.symbology = symbology;
    _this.input = input;
    _this.message = "\"" + _this.input + "\" is not a valid input for " + _this.symbology;
    return _this;
  }

  return InvalidInputException;
}(Error);

var InvalidElementException = function (_Error2) {
  _inherits(InvalidElementException, _Error2);

  function InvalidElementException() {
    _classCallCheck(this, InvalidElementException);

    var _this2 = _possibleConstructorReturn(this, (InvalidElementException.__proto__ || Object.getPrototypeOf(InvalidElementException)).call(this));

    _this2.name = "InvalidElementException";
    _this2.message = "Not supported type to render on";
    return _this2;
  }

  return InvalidElementException;
}(Error);

var NoElementException = function (_Error3) {
  _inherits(NoElementException, _Error3);

  function NoElementException() {
    _classCallCheck(this, NoElementException);

    var _this3 = _possibleConstructorReturn(this, (NoElementException.__proto__ || Object.getPrototypeOf(NoElementException)).call(this));

    _this3.name = "NoElementException";
    _this3.message = "No element to render on.";
    return _this3;
  }

  return NoElementException;
}(Error);

exports.InvalidInputException = InvalidInputException;
exports.InvalidElementException = InvalidElementException;
exports.NoElementException = NoElementException;
export default exports;