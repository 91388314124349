var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = linearizeEncodings; // Encodings can be nestled like [[1-1, 1-2], 2, [3-1, 3-2]
// Convert to [1-1, 1-2, 2, 3-1, 3-2]

function linearizeEncodings(encodings) {
  var linearEncodings = [];

  function nextLevel(encoded) {
    if (Array.isArray(encoded)) {
      for (var i = 0; i < encoded.length; i++) {
        nextLevel(encoded[i]);
      }
    } else {
      encoded.text = encoded.text || "";
      encoded.data = encoded.data || "";
      linearEncodings.push(encoded);
    }
  }

  nextLevel(encodings);
  return linearEncodings;
}

export default exports;