import _ITF5 from "./ITF";
import _ITF6 from "./ITF14";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ITF14 = exports.ITF = undefined;
var _ITF = _ITF5;

var _ITF2 = _interopRequireDefault(_ITF);

var _ITF3 = _ITF6;

var _ITF4 = _interopRequireDefault(_ITF3);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.ITF = _ITF2.default;
exports.ITF14 = _ITF4.default;
export default exports;